define("ember-data-storefront/mixins/loadable", ["exports", "@ember/object/mixin", "@ember/debug", "@ember/object/evented", "ember-data-storefront/mixins/loadable-model"], function (_exports, _mixin, _debug, _evented, _loadableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _mixin.default.create(_loadableModel.default, {
    showDeprecations: (0, _evented.on)('init', function () {
      (false && !(false) && (0, _debug.deprecate)('The Loadable mixin has been renamed to LoadableMixin. Please change all instances of Loadable in your app to LoadableMixin. Loadable will be removed in 1.0.', false, {
        id: 'ember-data-storefront.loadable',
        until: '1.0.0'
      }));
    })
  });
});